import { css } from '@emotion/react';
import { onMobile } from '@styles/responsive';

function Footer() {
  return (
    <footer css={mainContainer}>
      <img src="/new_logo_studio_white.png" width="219" height="16" alt="리얼월드 스튜디오 로고" />
      <div css={infoContainer}>
        <span>주식회사 유니크굿컴퍼니</span>
        <span>사업자등록번호 524-88-00746</span>
        <span>전화번호 070-8706-1010</span>
        <span>통신판매업신고번호 2018-서울성동-0760호</span>
        <span>대표이사 이은영, 송인혁</span>
        <span>서울특별시 성동구 연무장13길 8, 2층, 3층 (성수동2가, 메리히어)</span>
        <a
          target="_blank"
          style={{ color: '#878A9D', cursor: 'pointer' }}
          href="https://www.notion.so/uniquegoodcompany/317906e557704f59ad24a2be8bb49612?pvs=4"
          rel="noreferrer"
        >
          이용약관
        </a>
      </div>
    </footer>
  );
}

export default Footer;

const mainContainer = css`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background-color: #0a111e;
  color: #878a9d;
  padding: 20px;

  ${onMobile} {
    flex-direction: column;
    height: auto;
    margin-top: 36px;
  }
`;

const infoContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  justify-content: right;
  gap: 12px;
  font-size: 12px;
  word-break: keep-all;

  ${onMobile} {
    width: 100%;
    gap: 4px;
    justify-content: center;
    font-size: 8px;
  }

  span {
    text-align: center;
    word-break: keep-all;
  }
`;
