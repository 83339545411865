import { css } from '@emotion/react';
import {
  Avatar,
  Button,
  color,
  Modal,
  spacing,
  textStyleMedium,
  Typography
} from '@uniquegood/realworld-studio-design';
import { useHistory, useParams } from 'react-router-dom';

import { useIsMobile, useModalState, useRequest, useUser } from '@/hooks';
import { AlignCenterFlex, ColumnFlex, MarginBottom6, MarginLeft16 } from '@/styles';
import { onMobile } from '@/styles/responsive';
import { coreApi } from '@/api';
import { ClickEventName, track } from '@/track';

interface HeaderProps {
  showSettingButton: boolean;
}

function Header({ showSettingButton }: HeaderProps) {
  const isMobile = useIsMobile();
  const history = useHistory();

  const { appId } = useParams<AppParam>();

  const { data: user } = useUser();
  const { data: apps, mutate: mutateApps } = useRequest<RealWorldApp[]>('/apps');
  const { data: app } = useRequest<RealWorldApp>(`/apps/${appId}`);

  const { data: projects } = useRequest<CommonResponseModel<Project[]>>(
    `/apps/${appId}/projects`,
    {},
    {
      headers: {
        'x-rwd-api-version': '1.1'
      }
    }
  );
  const { data: followStatus } = useRequest<FollowStatus>(`/api/creators/${appId}/follow`);

  const publishedProjects = projects?.data.filter((project) => project.isVisibleDefault);

  const { modal, openModal, closeModal } = useModalState();

  function openLeaveModal() {
    openModal({
      size: 'small',
      title: '이 채널을 나가시겠어요?',
      children: `채널을 나가면 내 채널 목록에서 '${app?.name}'가 삭제되며 소유자의 초대 없이는
          다시 들어올 수 없어요.`,
      primaryAction: {
        onAction: async () => {
          if (!user) throw Error('채널 내 귀하의 사용자 정보를 조회하지 못했습니다.');

          await coreApi.delete<User[]>(`/apps/${appId}/members/${user.id}`);
          await mutateApps();

          if (!apps) throw Error();

          history.push(apps.length > 0 ? `/apps/${apps[0].id}` : 'createApp');

          closeModal();
        },
        type: 'destructive',
        content: '나가기'
      },
      secondaryAction: { content: '취소', onAction: closeModal }
    });
  }

  const headerButtonMarkup = showSettingButton ? (
    <Button
      icon="cog_solid"
      type="basic"
      size={isMobile ? 'medium' : 'large'}
      onClick={() => {
        history.push(`/apps/${appId}/settings`);

        track.onClick({ clickEventName: ClickEventName.click_channel_button_settings });
      }}
    >
      채널 설정
    </Button>
  ) : (
    <Button
      icon="sign_out_alt_solid"
      type="basic"
      size={isMobile ? 'medium' : 'large'}
      onClick={openLeaveModal}
    >
      채널 나가기
    </Button>
  );

  return (
    <>
      <div css={HeaderWrap}>
        <Avatar cssUserNameStyle={AvatarUserNameStyle} size="xLarge" source={app?.imageUrl} />
        <div css={MarginLeft16}>
          <Typography as="h2" type="displayMedium" cssStyle={MarginBottom6}>
            {app?.name}
          </Typography>
          <Typography as="span" type="body">
            {publishedProjects && `공개한 게임 ${publishedProjects.length}개`}
            {followStatus && <span css={followerCount}>팔로워 {followStatus.followCount}명</span>}
          </Typography>
        </div>
        <div css={HeaderButtonPos}>{headerButtonMarkup}</div>
      </div>
      <Modal {...modal} />
    </>
  );
}

export default Header;

const HeaderWrap = css`
  ${AlignCenterFlex};
  margin-bottom: 40px;

  ${onMobile} {
    ${ColumnFlex};

    margin-bottom: 40px;
  }
`;

const followerCount = css`
  margin-left: ${spacing.margin.xsmall};

  color: ${color.palette_primary_purple_100};
`;

const AvatarUserNameStyle = css`
  ${textStyleMedium};
  color: ${color.text_default};
  margin-bottom: ${spacing.margin.xsmall2};
`;

const HeaderButtonPos = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;

  ${onMobile} {
    margin-left: initial;
    margin-top: ${spacing.margin.large};
  }
`;
